// src/components/Header/Header.jsx
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  Box,
  CssBaseline,
  Divider,
} from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';
import { useTheme, useMediaQuery } from '@mui/material';

import uulogo from '../../assets/images/uulogo.png';
import CustomButton from '../CustomButton';
import {
  register as registerApi,
  login as loginApi,
  getUserInfo,
  logout as logoutApi,
} from '../../services/api';

function Header() {
  const { t, i18n } = useTranslation("header");
  const [isAuthModalOpen, setAuthModalOpen] = useState(false);
  const [user, setUser] = useState(null);

  // 语言、用户菜单的 anchorEl
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);
  const [userAnchorEl, setUserAnchorEl] = useState(null);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 打印 user 更新信息
  useEffect(() => {
    if (user) {
      console.log('User updated:', user);
    }
  }, [user]);

  // 获取用户信息
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      getUserInfo()
        .then((response) => {
          if (response.data.code === 200) {
            setUser(response.data);
          } else {
            localStorage.removeItem('token');
          }
        })
        .catch((error) => {
          console.error(error);
          localStorage.removeItem('token');
        });
    }
  }, []);

  // 切换语言
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleLanguageMenuClose();
  };

  // 语言菜单打开/关闭
  const handleLanguageMenuOpenHandler = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  // 用户菜单打开/关闭
  const handleUserMenuOpenHandler = (event) => {
    setUserAnchorEl(event.currentTarget);
  };
  const handleUserMenuClose = () => {
    setUserAnchorEl(null);
  };

  // 登录/注册模态框相关
  const openAuthModal = () => setAuthModalOpen(true);
  const closeAuthModal = () => setAuthModalOpen(false);

  const handleAuthSubmit = async (data, isLoginMode) => {
    try {
      let response;
      if (isLoginMode) {
        // 登录逻辑
        response = await loginApi(data.email, data.password);
        if (response.data.code === 200) {
          const token = response.data.data.satoken;
          localStorage.setItem('token', token);
        } else {
          alert(response.data.message);
          return;
        }
      } else {
        // 注册逻辑
        response = await registerApi(data.email, data.password);
        if (response.data.code === 200) {
          alert('注册成功！');
          const token = response.data.data.satoken;
          localStorage.setItem('token', token);
        } else {
          alert('注册失败！');
          return;
        }
      }
      response = await getUserInfo();
      setUser(response.data);
      closeAuthModal();
    } catch (error) {
      alert('操作失败，请检查您的输入或稍后再试');
    }
  };

  // 登出
  const handleLogout = async () => {
    try {
      const response = await logoutApi();
      if (response.data.code === 200) {
        localStorage.removeItem('token');
        alert('已退出登录: ' + response.data.message);
        setUser(null);
      } else {
        alert('退出登录失败: ' + response.data.message);
      }
    } catch (error) {
      console.error(error);
      alert('退出登录失败，请稍后再试');
    }
    window.location.reload();
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="static"
        sx={{
          backgroundColor: '#ffc2e9',
          fontFamily: 'Circular Std, sans-serif',
          boxShadow: 'none', // 去掉默认阴影
        }}
      >
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          {/* 左侧 Logo */}
          <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
            <RouterLink
              to="/"
              style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }}
            >
              <img src={uulogo} alt="UUTracking Logo" style={{ width: '50px', height: '50px' }} />
            </RouterLink>
            <Typography
              variant="h6"
              component="div"
              color="secondary"
              sx={{ marginLeft: 2, fontFamily: 'Circular Std, sans-serif' }}
            >
              UUTracking
            </Typography>
          </Box>

          {/* 右侧：语言按钮 + 用户按钮 */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {/* 语言按钮 */}
            <CustomButton
              variant="language"
              component={RouterLink} to="#" // 别改这里
              onClick={handleLanguageMenuOpenHandler}
              sx={{ mr: 1 }}
            >
              <LanguageIcon />
            </CustomButton>
            <Menu
              anchorEl={languageAnchorEl}
              open={Boolean(languageAnchorEl)}
              onClose={handleLanguageMenuClose}
              PaperProps={{
                sx: {
                  mt: 1,
                  borderRadius: 2, // 圆角
                  boxShadow: '0 4px 16px rgba(0,0,0,0.1)', // 阴影
                  fontFamily: 'Circular Std, sans-serif',
                },
              }}
            >
              <MenuItem onClick={() => changeLanguage('en')}>English</MenuItem>
              <MenuItem onClick={() => changeLanguage('zh')}>中文</MenuItem>
            </Menu>

            {/* 用户菜单 */}
            {user ? (
              <>
                <CustomButton
                  variant="language"
                  component={RouterLink} to="#" // 别改这里
                  // onClick={handleUserMenuOpenHandler}
                  sx={{ borderRadius: '50%', width: 40, height: 40, mr: 1 }}
                >
                  <MarkUnreadChatAltIcon />
                </CustomButton>

                <CustomButton
                  variant="language"
                  component={RouterLink} to="#" // 别改这里
                  onClick={handleUserMenuOpenHandler}
                  sx={{ borderRadius: '50%', width: 40, height: 40 }}
                >
                  <AccountCircleIcon />
                </CustomButton>
                <Menu
                  anchorEl={userAnchorEl}
                  open={Boolean(userAnchorEl)}
                  onClose={handleUserMenuClose}
                  PaperProps={{
                    sx: {
                      mt: 1,
                      borderRadius: 2,
                      boxShadow: '0 4px 16px rgba(0,0,0,0.1)',
                      minWidth: 220,
                      fontFamily: 'Circular Std, sans-serif',
                    },
                  }}
                >
                  {/* 用户信息（不可点击） */}
                  <Box sx={{ px: 2, py: 1, borderBottom: '1px dashed #ccc' }}>
                    <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                      {user.data || 'Casey Li'}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'gray' }}>
                      {user.data || 'luoyanyu98127@gmail.com'}
                    </Typography>
                  </Box>

                  {/* 菜单项 */}
                  <MenuItem component={RouterLink} to="/user-dashboard" onClick={handleUserMenuClose}>
                    {t('dashboard')}
                  </MenuItem>
                  <MenuItem component={RouterLink} to="/uu-tracking-orders" onClick={handleUserMenuClose}>
                    {t('settings')}
                  </MenuItem>

                  {/* 分割线 */}
                  <Box sx={{ borderTop: '1px dashed #ccc', my: 1 }} />

                  {/* 登出 */}
                  <MenuItem
                    onClick={() => {
                      handleUserMenuClose();
                      handleLogout();
                    }}
                  >
                    {t('signOut')}
                  </MenuItem>
                </Menu>
              </>
            ) : (
              <CustomButton component={RouterLink} to="/login">
                {t('login')}
              </CustomButton>
            )}
          </Box>
        </Toolbar>
      </AppBar>

      {/* 你如果还有 AuthModal 可以放在这里 */}
      {/* <AuthModal
        isOpen={isAuthModalOpen}
        onRequestClose={closeAuthModal}
        onSubmit={handleAuthSubmit}
      /> */}
    </>
  );
}

export default Header;