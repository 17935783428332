// src/components/ForgotPasswordPage/ForgotPasswordPage.jsx
import React, { useState } from 'react';
import {
  Container,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
// import { forgotPasswordApi } from '../services/api'; // 你实际的忘记密码接口

const schema = yup.object().shape({
  email: yup.string().email('请输入有效的邮箱').required('邮箱不能为空'),
});

function ForgotPasswordPage() {
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    setSubmitError('');
    setSuccessMessage('');
    try {
      // 假设这里调用你写好的 forgotPasswordApi
      // const response = await forgotPasswordApi(data.email);
      // if (response.data.code === 200) {
      //   setSuccessMessage('密码重置链接已发送到 ' + data.email);
      // } else {
      //   setSubmitError('发送失败，请稍后再试');
      // }

      // 这里用 alert 模拟
      alert(`密码重置链接已发送到 ${data.email}`);
      setSuccessMessage(`密码重置链接已发送到 ${data.email}`);
    } catch (error) {
      console.error(error);
      setSubmitError('发送失败，请稍后再试');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          忘记密码
        </Typography>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMessage}
          </Alert>
        )}
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 1 }}
        >
          <TextField
            label="邮箱"
            type="email"
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
            fullWidth
            required
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting}
          >
            发送密码重置链接
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default ForgotPasswordPage;