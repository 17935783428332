// src/components/Track/Track.jsx
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams, useNavigate } from 'react-router-dom';
import './Track.css';

import DeliveryStatusCard from '../DeliveryStatusCard/DeliveryStatusCard';

import { getUserInfo } from '../../services/api';
import { getMailInfoUniversal } from '../../services/api_mail';
import { getTrackAll } from '../../services/api_track';

function Track() {
  const { t } = useTranslation();
  const { trackingNumber: initialTrackingNumber } = useParams();
  const navigate = useNavigate();

  const [trackingNumber, setTrackingNumber] = useState(initialTrackingNumber || '');
  const [mailInfo, setMailInfo] = useState(null);
  const [trackingRecords, setTrackingRecords] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // =========== 下面是新增的 4 个状态，用于控制 DeliveryStatusCard ===========

  // 1) 是否 Pending（若 true，则只显示 "Pending" 字样，隐藏具体日期/时间）
  const [isPending, setIsPending] = useState(false);

  // 2) 预计送达日期，如 "December 23, 2024"
  const [expectedDeliveryDate, setExpectedDeliveryDate] = useState('');

  // 3) 预计送达时间，如 "9:00pm"
  const [expectedDeliveryTime, setExpectedDeliveryTime] = useState('');

  // 4) 状态解释文字
  const [statusExplanation, setStatusExplanation] = useState('');

  // =====================================================================

  // 检查登录状态
  useEffect(() => {
    const checkLoginStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        if (token) {
          // 获取用户信息，判断是否登录
          const response = await getUserInfo();
          if (response.data.code === 200) {
            setIsLoggedIn(true);
          } else {
            setIsLoggedIn(false);
            localStorage.removeItem('token'); // 若token失效，清除
          }
        } else {
          setIsLoggedIn(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoggedIn(false);
      } finally {
        setIsLoading(false);
      }
    };
    checkLoginStatus();
  }, []);

  // 当登录状态检查完毕（isLoading=false）且 trackingNumber 有值时，拉取数据
  useEffect(() => {
    if (!isLoading && trackingNumber) {
      fetchMailInfo();
      fetchTrackingRecords();
    }
  }, [trackingNumber, isLoading]);

  // 拉取邮件信息
  const fetchMailInfo = async () => {
    try {
      // 统一接口
      const response = await getMailInfoUniversal(trackingNumber);
      if (response.data.code === 200) {
        const info = response.data.data;
        setMailInfo(info);

        // =========== 这里示例如何设置 DeliveryStatusCard 的字段 ===========
        // 你可根据后端返回决定是否 Pending，以及日期/时间的值

        // 假设后端返回 info.estimatedDelivery(如 "2024-12-23T21:00:00Z") 来表示预计送达时间
        // 如果后端还没评估出送达日期则返回 null -> 你就设置 isPending(true)
        if (!info.estimatedDelivery) {
          // 示例解析
          // 这里是示例做法，实际需看后端返回的数据格式
          const dateObj = new Date(info.estimatedDelivery);
          // month -> "December 23, 2024"
          const dateString = dateObj.toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          });
          // time -> "9:00pm"
          const timeString = dateObj.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: '2-digit',
            hour12: true,
          });

          setIsPending(false);
          setExpectedDeliveryDate("March 31, 2024");
          setExpectedDeliveryTime("9:00pm");

          // 设置状态解释
          setStatusExplanation(
            // 你可以根据 info 里更多信息来生成解释
            // 也可以写死一段字符串
            `This mailpiece is currently in transit to the destination.`
          );
        } else {
          // 代表后端没有具体日期 -> Pending
          setIsPending(true);
          setExpectedDeliveryDate('');
          setExpectedDeliveryTime('');
          setStatusExplanation('We are still estimating your delivery date. Please check back later.');
        }
      } else {
        setMailInfo(null);
      }
    } catch (error) {
      console.error(error);
      alert(t('networkError'));
    }
  };

  // 拉取追踪记录
  const fetchTrackingRecords = async () => {
    try {
      const response = await getTrackAll(trackingNumber);
      if (response.data.code === 200) {
        setTrackingRecords(response.data.data);
      } else {
        setTrackingRecords([]);
      }
    } catch (error) {
      console.error(error);
      alert(t('networkError'));
    }
  };

  // 搜索框提交
  const handleSearch = (e) => {
    e.preventDefault();
    const inputTrackingNumber = e.target.elements.trackingNumber.value.trim();
    if (inputTrackingNumber) {
      setTrackingNumber(inputTrackingNumber);
      navigate(`/track/${inputTrackingNumber}`);
    } else {
      setTrackingNumber('');
    }
  };

  // 订阅按钮点击（示例）
  const handleSubscribeClick = () => {
    alert('This feature is under maintenance and is expected to be restored on April 2. Sorry for any inconvenience.');
  };

  // 工具函数：将 eventTime 数组转化为日期
  function convertArrayToDate(dateArray) {
    if (Array.isArray(dateArray) && dateArray.length >= 5) {
      const [year, month, day, hour, minute] = dateArray;
      return new Date(year, month - 1, day, hour, minute);
    }
    // 如果取不到，则给个固定的测试时间
    return new Date(2024, 10, 1, 1, 1);
  }

  // 翻译函数，具体可按你后端的值
  function mailTypeTranslator(mailType) {
    // ...
    if (mailType === 1210) return t('UUTrackingLetterPostcardInternational');
    if (mailType === 1220) return t('UUTrackingLargeEnvolopeInternational');
    if (mailType === 1120) return t('UUTrackingLargeEnvolopeDomestic');
    if (mailType === 1110) return t('UUTrackingLetterPostcardDomestic');
    return t('UUTrackingUnknownMailType');
  }

  function mailStatusTranslator(mailStatus) {
    // ...
    if (mailStatus === 200) return t('UUTrackingMailTypeStatusCreate');
    if (mailStatus === 250) return t('UUTrackingMailTypeStatusInTransit');
    if (mailStatus === 300) return t('UUTrackingMailTypeStatusDelivered');
    return t('UUTrackingMailTypeStatusUnknown');
  }

  function mapEvent(eventCode) {
    const eventMapping = {
      1: t('userAddedDeliveryRecord'),
      2: t('postageCancellation'),
      3: t('originPrimaryProcessing'),
      4: t('destinationProcessing'),
      5: t('destinationSorting'),
      6: t('destinationMailboxDrop'),
      7: t('delivery'),
      8: t('internationalOutboundScan')
    };
    return eventMapping[eventCode] || t('unknownEvent', `未知事件（${eventCode}）`);
  }

  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  };

  return (
    <div className="track-page">
      {/* 搜索表单 */}
      <form className="search-form" onSubmit={handleSearch}>
        <input
          type="text"
          name="trackingNumber"
          placeholder={t('enterTrackingNumber')}
          defaultValue={trackingNumber}
        />
        <button type="submit">{t('search')}</button>
      </form>

      {/* 当前正在查询的运单号 */}
      {trackingNumber && (
        <div className="current-tracking-number">
          <h2>
            {t('currentTrackingNumber')}: {trackingNumber}
          </h2>
        </div>
      )}

      {/* ============== 这里插入 DeliveryStatusCard，显示预计送达、状态说明等 ============== */}
      <DeliveryStatusCard
        color="pink"                   // 可选 'gray' | 'blue' | 'red' | 'pink' ...
        isPending={isPending}         // 决定是否只显示 Pending
        expectedDate={expectedDeliveryDate}
        expectedTime={expectedDeliveryTime}
        statusExplanation={statusExplanation}
        linkText={t('subscribeTrackingUpdates')}  // 链接按钮文字
        onLinkClick={handleSubscribeClick}        // 链接点击事件
      />

      {/* 如果查到了邮件信息 */}
      {mailInfo && (
        <div className="mail-info card">
          {mailInfo.responseType === 1 ? (
            <div className="details">
              <h3>{t('mailDetails')}</h3>
              <p>
                {t('mailType')}: {mailTypeTranslator(mailInfo.mailType)}
              </p>
              <p>{t('originAddress')}: {mailInfo.originFullAddress}</p>
              <p>{t('destinationAddress')}: {mailInfo.destinationFullAddress}</p>
              <p>{t('trackingNumber')}: {mailInfo.trackingNumber}</p>
            </div>
          ) : (
            <div className="summary">
              <h3>{t('mailSummarys')}</h3>
              <p>
                {t('mailType')}: {mailTypeTranslator(mailInfo.mailType)}
              </p>
              <p>{t('originAddress')}: {mailInfo.originBriefAddress}</p>
              <p>{t('destinationAddress')}: {mailInfo.destinationBriefAddress}</p>
              <p>{t('trackingNumber')}: {mailInfo.trackingNumber}</p>
            </div>
          )}
        </div>
      )}

      {/* 如果有追踪事件，则显示时间线，否则提示无记录 */}
      {trackingRecords.length > 0 ? (
        <div className="timeline">
          <h3>{t('trackingUpdates')}</h3>
          <ul>
            {trackingRecords.map((record) => (
              <li key={record.trackingId}>
                <div className="timeline-content">
                  <span className="timeline-date">
                    {record.eventTime && convertArrayToDate(record.eventTime)
                      ? convertArrayToDate(record.eventTime).toLocaleString('en-US', dateOptions)
                      : t('invalidDate')}
                  </span>
                  <p>{t('event')}: {mapEvent(record.event)}</p>
                  <p>{t('location')}: {record.currentLocation}, {record.currentCity}, {record.currentState} {record.currentZip}</p>
                  <p>{t('status')}: {record.status === 1 ? t('normal') : t('abnormal')}</p>
                </div>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        trackingNumber && <p>{t('noTrackingRecords')}</p>
      )}
    </div>
  );
}

export default Track;