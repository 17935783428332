// src/compoents/LoginPage/LoginPage.jsx
import React, { useState } from 'react';
import {
  Container,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Link as MuiLink,
  Alert,
  CircularProgress,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom'; // 需要 react-router-dom v6+
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { login as loginApi, getUserInfo } from '../../services/api'; // 根据你项目的路径调整

// 定义表单校验规则
const schema = yup.object().shape({
  email: yup.string().email('请输入有效的邮箱').required('邮箱不能为空'),
  password: yup.string().min(6, '密码至少为6位').max(16, '密码最多为16位').required('密码不能为空'),
});

function LoginPage() {
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // 登录表单提交
  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      setSubmitError('');

      // 调用登录接口
      const response = await loginApi(data.email, data.password);
      if (response.data.code === 200) {
        // 存储 token
        localStorage.setItem('token', response.data.data.satoken);

        // 获取用户信息
        const userInfo = await getUserInfo();
        console.log('登录成功:', userInfo.data);

        // 跳转到首页或用户主页
        navigate('/');
      } else {
        setSubmitError(response.data.message || '登录失败');
      }
    } catch (error) {
      console.error(error);
      setSubmitError('操作失败，请检查您的输入或稍后再试');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, mb: 4 }}>
      {/* Paper 让表单有一个卡片的效果 */}
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          登录 UUTracking
        </Typography>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="邮箱"
            type="email"
            margin="normal"
            fullWidth
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            label="密码"
            type="password"
            margin="normal"
            fullWidth
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <FormControlLabel
              control={<Checkbox defaultChecked color="primary" />}
              label="自动登录"
            />
            <MuiLink component={Link} to="/forgot-password" variant="body2">
              忘记密码？
            </MuiLink>
          </Box>
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2, position: 'relative' }}
            disabled={isSubmitting}
          >
            登录
            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{ position: 'absolute', right: 16, top: '50%', marginTop: '-12px' }}
              />
            )}
          </Button>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            还没有账号？{' '}
            <MuiLink component={Link} to="/register">
              立即注册
            </MuiLink>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default LoginPage;