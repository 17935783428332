// src/components/CustomButton.jsx
import React from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/system';
import PropTypes from 'prop-types';
// import './CustomButton.css';


const lightViolet = '#E6E6FA'; // 非常浅的紫罗兰色
const darkViolet = '#9400D3';  // 深紫罗兰色

const StyledButton = styled(Button)(({ buttonType }) => ({
  textTransform: 'none',
  backgroundColor: lightViolet,
  border: `1px solid ${darkViolet}`,
  color: darkViolet,
  borderRadius: '16px',
  minWidth: buttonType === 'language' ? '40px' : '60px', // 语言按钮较窄，其他按钮宽度为120px
  height: '40px',
  padding: buttonType === 'language' ? '0px 15px' : '0px 20px', // 语言按钮无左右内边距
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all 0.3s ease',
  fontFamily: 'Circular Std, sans-serif', // 应用 Circular Std 字体
  fontSize: '16px',
  '&:hover': {
    backgroundColor: darkViolet,
    border: `1px solid ${lightViolet}`,
    color: lightViolet,
  },
}));

// const CustomButton = ({ children, onClick, variant }) => {
//   return (
//     <StyledButton onClick={onClick} buttonType={variant} > 
//       {children}
//     </StyledButton>
//   );
// };

const CustomButton = ({ children, onClick, variant, ...rest }) => {
  // 此处 variant 是自定义的，我们不会把它传递给底层组件
  return (
    <StyledButton onClick={onClick} buttonType={variant} {...rest}>
      {children}
    </StyledButton>
  );
};

CustomButton.propTypes = {
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'language']),
};

CustomButton.defaultProps = {
  variant: 'default',
};

export default CustomButton;