// src/components/RegisterPage/RegisterPage.jsx
import React, { useState } from 'react';
import {
  Container,
  Paper,
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Link as MuiLink,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { register as registerApi, getUserInfo } from '../../services/api'; 

const schema = yup.object().shape({
  email: yup.string().email('请输入有效的邮箱').required('邮箱不能为空'),
  password: yup.string().min(6, '密码至少为6位').max(16, '密码最多为16位').required('密码不能为空'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], '两次密码输入不一致')
    .required('确认密码不能为空'),
});

function RegisterPage() {
  const navigate = useNavigate();
  const [submitError, setSubmitError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      setIsSubmitting(true);
      setSubmitError('');

      const response = await registerApi(data.email, data.password);
      if (response.data.code === 200) {
        // 注册成功后存储 token
        localStorage.setItem('token', response.data.data.satoken);
        // 获取用户信息
        const userInfo = await getUserInfo();
        console.log('注册成功:', userInfo.data);

        // 跳转到首页或登录页
        navigate('/');
      } else {
        setSubmitError(response.data.message || '注册失败');
      }
    } catch (error) {
      console.error(error);
      setSubmitError('操作失败，请稍后再试');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container component="main" maxWidth="xs" sx={{ mt: 8, mb: 4 }}>
      <Paper elevation={3} sx={{ p: 4 }}>
        <Typography variant="h5" align="center" gutterBottom>
          注册新账号
        </Typography>
        {submitError && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {submitError}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            label="邮箱"
            type="email"
            margin="normal"
            fullWidth
            {...register('email')}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            label="密码"
            type="password"
            margin="normal"
            fullWidth
            {...register('password')}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
          <TextField
            label="确认密码"
            type="password"
            margin="normal"
            fullWidth
            {...register('confirmPassword')}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword?.message}
          />
          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mt: 2, position: 'relative' }}
            disabled={isSubmitting}
          >
            注册
            {isSubmitting && (
              <CircularProgress
                size={24}
                sx={{ position: 'absolute', right: 16, top: '50%', marginTop: '-12px' }}
              />
            )}
          </Button>
          <Box sx={{ textAlign: 'center', mt: 2 }}>
            已有账号？{' '}
            <MuiLink component={Link} to="/login">
              立即登录
            </MuiLink>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default RegisterPage;