import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Button } from 'antd';
import { useTheme, useMediaQuery } from '@mui/material';
import './ChooseBarcodeOption.css';

function ChooseBarcodeOption() {
  const location = useLocation();
  const navigate = useNavigate();
  const { trackingNumber, requestData, imb } = location.state || {};

  // 先调用所有 Hooks
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // 使用 useEffect 来处理缺少数据的情况
  React.useEffect(() => {
    if (!trackingNumber || !requestData) {
      navigate('/send-letter');
    }
  }, [trackingNumber, requestData, navigate]);

  // 如果数据缺失，则不要渲染内容
  if (!trackingNumber || !requestData) {
    return null;
  }
  const handlePrintNow = async () => {
    try {
      // 构建请求数据
      const requestPayload = {
        type: 2,
        imb_data: {
          barcode_id: imb.slice(0, 2),         // 例如 '00'
          service_type: imb.slice(2, 5),         // 例如 '040'
          mail_class: imb.slice(5, 14),          // 例如 '903666417'
          serial_number: imb.slice(14, 20),      // 例如 '000036'
          zip_code: imb.slice(20),               // 例如 '91775122923'
        },
        tracking_no: trackingNumber,
        sender: `${requestData.originFullName}\n${requestData.originAddress1}\n${requestData.originCity}, ${requestData.originState} ${requestData.originZip}`,
        recipient: `${requestData.destinationFullName}\n${requestData.destinationAddress1}\n${requestData.destinationCity || ''}, ${requestData.destinationState} ${requestData.destinationZip || ''}`
      };

      // 发送 POST 请求获取 PDF 下载链接
      const response = await fetch('https://uutracking.com/pdf-gen/generate-pdf', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', // 如果需要发送凭证，如 Cookies
        body: JSON.stringify(requestPayload)
      });

      if (response.ok) {
        const data = await response.json();
        const downloadUrl = data.downloadUrl;
        if (downloadUrl) {
          // 触发下载
          window.location.href = downloadUrl;
          // 打印指南提示
          alert('打印指南：\n1. 使用Letter纸张打印，打印后剪下条码并粘贴到信封上。\n2. 单号生成后，目的地地址无法修改，如需修改地址请务必作废当前单号和条码，并使用正确的地址重新生成新单号、新条码。\n3. 务必在下单后15天内寄出，超时将回收。');
        } else {
          alert('获取下载链接失败');
        }
      } else {
        const errorData = await response.json();
        alert(`错误: ${errorData.error || '获取下载链接失败'}`);
      }
    } catch (error) {
      console.error(error);
      alert('网络错误，请稍后重试');
    }
  };

  // 邮寄条码处理函数
  const handleMailBarcode = () => {
    navigate('/mail-barcode', { state: { trackingNumber, requestData } });
  };

  return (
    <div className="choose-barcode-option">
      <h1 className="tracking-title">成功生成单号：{trackingNumber}</h1>
      <div className="options">
        <Card className="option-card">
          <h2>立即打印</h2>
          <p>使用打印机打印条码，并贴在信封/明信片上。</p>
          <Button type="primary" onClick={handlePrintNow}>立即打印</Button>
        </Card>
        <Card className="option-card">
          <h2>邮寄条码</h2>
          <p>由我们为您打印条码，1-3天邮寄到您的手中！</p>
          <Button type="primary" onClick={handleMailBarcode}>邮寄条码</Button>
        </Card>
      </div>

      {/* 信封预览区域，采用信封样式显示，左上角显示寄信人，右下角显示收信人 */}
      <div className="envelope-preview">
        <h2>信封预览</h2>
        <div className="envelope">
          <div className="sender">
            <h3>寄信人</h3>
            <p>{requestData.originFullName}</p>
            <p>{requestData.originAddress1}</p>
            <p>{requestData.originCity}, {requestData.originState} {requestData.originZip}</p>
          </div>
          <div className="recipient">
            <h3>收信人</h3>
            <p>{requestData.destinationFullName}</p>
            <p>{requestData.destinationAddress1}</p>
            <p>{requestData.destinationCity ? requestData.destinationCity + ', ' : ''}{requestData.destinationState} {requestData.destinationZip || ''}</p>
          </div>
        </div>
      </div>

      {/* 步骤说明区域 */}
      <div className="steps-section">
        <h2>接下来的步骤：</h2>
        {isMobile ? (
          <>
            <div className="step-card-mobile">
              <h2>步骤 1: 获得条码</h2>
              <img src="/path/to/step1-image.png" alt="Step 1" className="step-image-mobile" />
              <p>
                如果你有打印机，请点击“立即打印”按钮打印条码，支持多种纸张规格；<br />
                如果没有打印机，可选择“邮寄条码”，我们将在1-3天内为你邮寄；<br />
                还可选择“手写条码”，快速生成EasyLabel手写条码模板！
              </p>
            </div>
            <div className="step-card-mobile">
              <h2>步骤 2: 贴上条码</h2>
              <img src="/path/to/step2-image.png" alt="Step 2" className="step-image-mobile" />
              <p>
                将条码贴到信封或明信片的指定位置，确保条码与信封的最长边平齐，并位于右下角。
              </p>
            </div>
            <div className="step-card-mobile">
              <h2>步骤 3: 应用足够邮资</h2>
              <img src="/path/to/step3-image.png" alt="Step 3" className="step-image-mobile" />
              <p>
                确保已贴足够邮资，参照USPS官网计算邮资，避免邮件被退回或销毁。
              </p>
            </div>
            <div className="step-card-mobile">
              <h2>步骤 4: 投递邮件</h2>
              <img src="/path/to/step4-image.png" alt="Step 4" className="step-image-mobile" />
              <p>
                将信件或明信片投入USPS接收设施（如蓝色收集箱、邮局收集箱或柜台交寄）。
              </p>
            </div>
          </>
        ) : (
          <>
            <div className="step-card-pc">
              <div className="step-content">
                <h2>步骤 1: 获得条码</h2>
                <p>
                  如果你有打印机，请点击“立即打印”按钮打印条码，支持多种纸张规格；<br />
                  如果没有打印机，可选择“邮寄条码”，我们将在1-3天内为你邮寄；<br />
                  还可选择“手写条码”，快速生成EasyLabel手写条码模板！
                </p>
              </div>
              <img src="/path/to/step1-image.png" alt="Step 1" className="step-image-right" />
            </div>
            <div className="step-card-pc">
              <img src="/path/to/step2-image.png" alt="Step 2" className="step-image-left" />
              <div className="step-content">
                <h2>步骤 2: 贴上条码</h2>
                <p>
                  将条码贴到信封或明信片的指定位置，确保条码与信封的最长边平齐，并位于右下角。
                </p>
              </div>
            </div>
            <div className="step-card-pc">
              <div className="step-content">
                <h2>步骤 3: 应用足够邮资</h2>
                <p>
                  确保已贴足够邮资，参照USPS官网计算邮资，避免邮件被退回或销毁。
                </p>
              </div>
              <img src="/path/to/step3-image.png" alt="Step 3" className="step-image-right" />
            </div>
            <div className="step-card-pc">
              <img src="/path/to/step4-image.png" alt="Step 4" className="step-image-left" />
              <div className="step-content">
                <h2>步骤 4: 投递邮件</h2>
                <p>
                  将信件或明信片投入USPS接收设施（如蓝色收集箱、邮局收集箱或柜台交寄）。
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ChooseBarcodeOption;