// src/components/Footer/Footer.jsx
import React from 'react';
import {
  Box,
  Container,
  Grid,
  Link as MuiLink,
  IconButton,
  Typography,
  useTheme,
} from '@mui/material';

// MUI Icons (你可以自行替换)
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
// 如果“rednote”没有官方图标，可以用一个占位符或自定义图标
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'; // 这里只是示例
import uulogo from '../../assets/images/uulogo.png';

function Footer() {
  const theme = useTheme();

  return (
    <Box
      component="footer"
      sx={{
        py: 3,
        backgroundColor: '#FFEEFF', // 浅灰色背景
        color: '#550045',
        borderTop: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Container maxWidth="lg">
        <Grid container alignItems="center">
          {/* 左侧：Logo + 链接 */}
          <Grid item xs={12} sm={6}>
            <Box display="flex" alignItems="center">
              {/* LOGO */}
              <Box sx={{ mr: 2 }}>
                <img
                  src={uulogo}
                  alt="Logo"
                  style={{ width: '40px', height: '40px' }}
                />
              </Box>
              {/* 文字链接 */}
              <Box>
                <MuiLink
                  href="/about"
                  color="inherit"
                  underline="hover"
                  sx={{ mr: 2 }}
                >
                  关于
                </MuiLink>
                <MuiLink
                  href="/contact"
                  color="inherit"
                  underline="hover"
                  sx={{ mr: 2 }}
                >
                  联系我们
                </MuiLink>
                <MuiLink
                  href="/terms"
                  color="inherit"
                  underline="hover"
                >
                  用户协议
                </MuiLink>
              </Box>
            </Box>
          </Grid>

          {/* 右侧：社交媒体图标 */}
          <Grid item xs={12} sm={6}>
            <Box display="flex" justifyContent="flex-end">
              {/* X (Twitter) */}
              <IconButton
                color="inherit"
                href="https://twitter.com/your_account"
                target="_blank"
              >
                <TwitterIcon />
              </IconButton>
              {/* YouTube */}
              <IconButton
                color="inherit"
                href="https://youtube.com/your_account"
                target="_blank"
              >
                <YouTubeIcon />
              </IconButton>
              {/* rednote - 用 LibraryMusicIcon 代替 */}
              <IconButton
                color="inherit"
                href="https://rednote.com/your_account"
                target="_blank"
              >
                <LibraryMusicIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
        {/* 如果需要版权或其他信息，可以加在下面 */}
        <Box mt={2} textAlign="center">
          <Typography variant="body2" color="text.secondary">
            © 2025 UUTracking.com All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;